import './center.css'
import React from 'react';

function Center() {
  return (
    <div className="box" id="center">

        <div className='title-header'>

          <div className='header-left'>
              <div className='left-photo'> <img src='/image/imagecenter.jpeg' alt=''/> </div>
              <div className='left-bottom-container'>
                <div className='textHuge'> <span>Made </span>  Just For <span>You</span></div>
                <div className='textSmall'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                    Beatae provident aliquid eum nulla neque voluptate temporibus, 
                    laborum aut dolorum distinctio laboriosam, 
                    maiores suscipit assumenda
                </div>
              </div>
              <div className='btnSeeAll'>
                <div className='boxSeeAll'>
                    <div className='txtSeeAll'>
                        See All <span>&#8658;</span>
                    </div>
                </div>
              </div>
              
              
          </div>
          
          <div className='header-right'>
              <div className='titleTrack'>
                <div>What's For Today?</div>
              </div>
              <div className='topTodayDisplay'>
                  <div className='topToday'> <img src='/image/todaytop.jpg' alt=''/>  </div>
                  <div className='topTodayBackground'>Today's Top List</div>
              </div>

              <div className='newSongDisplay'>
                  <div className='newSong'> <img src='/image/newsong.png' alt=''/>  </div>
                  <div className='newSongBackgorund'>New Song</div>
              </div>
              

              <div className='discoverBackgroundDisplay'>
                  <div className='discoverSong'> <img src='/image/discover.jpg' alt=''/> </div>
                  <div className='discoverSongBackground'>Discover</div>
              </div>
          </div>

        </div>

    </div>
      
  );
}

export default Center;
