import './header.css'
import React from 'react';
import {Link} from 'react-scroll'

function Header() {
  return (
      <div className="box-header">
        <div className="top">
            <div className="title">
                <span>Tuned </span>In
            </div>
            <div className="menu-bar">
                <div className="a"><Link to="center" smooth={true} duration={1000}>Home</Link></div>
                <div className="b"><Link to="service" smooth={true} duration={1000}>Our Service</Link></div>
                <div className="c"><Link to="contact" smooth={true} duration={1000}>Contact Us</Link></div>
            </div>
            <div className='signUp-logIn'>
                <div className='logIn'>LogIn</div>
                <div className='signUp'>SignUp</div>
                
            </div>
        </div>
        <div className="center">
            <div className='center-left-column'>
                <div className="center-left">
                    <div className='text'>Explore all kinds of <span>music</span> that you like.</div>
                </div>
                <div className='center-left-bottom'>A website that's provide all kinds of music from around the world</div>
            </div>

            <div className="center-right">
                <div className="photo-music">
                    <img src="/image/listening-music.png"alt=''/>
                </div>
            </div>
        </div>
      </div>
  );
}

export default Header;
