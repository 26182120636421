import React from 'react';
import './contact.css'

function Contact() {
  return (
    <div className='contact-header' id='contact'>
            <div className='logoTitle'>
            <span> Tuned </span> In
            </div>
            <div className='sosmed'>
                <img src='image/instagram.png' alt=''/>
                <img src='image/facebook.png' alt=''/>
                <img src='image/whatsapp.png' alt=''/>
            </div>
    </div>
      
  );
}

export default Contact;
