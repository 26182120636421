import React from 'react';
import './service.css'

function Service() {
  return (
    <div className='service-box' id='service'>
      <div className='service-header'>
          <div className='serviceTitle'>
                Explore Your <span> Journey </span>  With Our <span> Service </span>
          </div>

          <div className='box-membership'>
              
              <div className='bronze'>
                  <div className='basic'>
                      <img src='image/basic.png' alt=''/>
                  </div>
                  <div className='planName'>
                        Beginner Plan
                  </div>
                  <div className='costPlan'>
                      $ 5
                  </div>
                  <div className='benefit'>
                      <div className='benefitList'>
                      <img src='/image/checklist.png' alt=''/> 
                        Listening to music without ads
                      </div>
                      <div className='benefitList'>
                      <img src='/image/checklist.png' alt=''/> 
                        Download music up to 100 music
                      </div>
                      <div className='benefitList'>
                      <img src='/image/checklist.png' alt=''/> 
                        Customized playlist
                      </div>
                  </div>
                  <div className='btnJoin'>
                      <div className='boxBtn'>
                        <div className='textBtn'>
                              Join Now
                        </div>
                      </div>
                  </div>
              </div>
              <div className='gold'>
                  <div className='crown'>
                      <img src='image/crown.png' alt=''/>
                  </div>
                  <div className='planName'>
                    Premium Plan
                </div>
                <div className='costPlan'>
                  $ 30
                </div>
                <div className='benefit'>
                    <div className='benefitList'>
                      <img src='/image/checklist.png' alt=''/> 
                        Listening to music without ads
                      </div>
                      <div className='benefitList'>
                      <img src='/image/checklist.png' alt=''/> 
                        Unlimited download music
                      </div>
                      <div className='benefitList'>
                      <img src='/image/checklist.png' alt=''/> 
                        Customized playlist
                      </div>
                      <div className='benefitList'>
                      <img src='/image/checklist.png' alt=''/> 
                        Available lyrics music on display
                      </div>
                      <div className='btnJoinPremium'>
                      <div className='boxBtn'>
                        <div className='textBtn'>
                              Join Now
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div className='silver'>
                  <div className='average'>
                      <img src='image/average.png' alt=''/>
                  </div>
                    <div className='planName'>
                        Average Plan
                  </div>
                  <div className='costPlan'>
                      $ 15
                  </div>
                  <div className='benefit'>
                    <div className='benefitList'>
                      <img src='/image/checklist.png' alt=''/> 
                        Listening to music without ads
                      </div>
                      <div className='benefitList'>
                      <img src='/image/checklist.png' alt=''/> 
                        Download music up to 500 music
                      </div>
                      <div className='benefitList'>
                      <img src='/image/checklist.png' alt=''/> 
                        Customized playlist
                      </div>
                  </div>
                    <div className='btnJoin'>
                        <div className='boxBtn'>
                          <div className='textBtn'>
                                Join Now
                          </div>
                        </div>
                    </div>
              </div>
          </div>

      </div>
    </div>
      
  );
}

export default Service;
