import React from 'react';
import './App.css';
import Header from './component/header';
import Center from './component/center';
import Service from './component/service';
import Contact from './component/contact';

function App() {
  return (
    <div>
      <Header/>
      <Center/>
      <Service/>
      <Contact/>
    </div>
      
  );
}

export default App;
